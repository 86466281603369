<template>
  <div>
    <div>
      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12 w-100">
        <b-col
          cols="12"
        >
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
      </b-row>
    </div>
    <!-- Error page-->
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <!-- image -->
        <b-img
          fluid
          :src="img"
          class="img"
          alt="img"
        />
        <div class="w-100 text-center mt-2">
          <h2 class="mb-1 title">
            {{ $t('register.verifyEmailFail.title') }}
          </h2>
          <p class="mb-2 des">
            {{ $t('register.verifyEmailFail.content') }}
          </p>
          <btn-loading
            variant-convert="primary"
            class="mb-2 btn-sm-block"
            :to="{name:'register'}"
          >
            {{ $t('register.btnSignUp') }}
          </btn-loading>
        </div>
      </div>
    </div>
  <!-- / Error page-->
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BImg, BRow, BCol,
} from 'bootstrap-vue'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BtnLoading,
    BrandLogo,
    BImg,
    BRow,
    BCol,
  },

  data() {
    return {
      img: require('@/assets/images/pages/auth/verify-fail.png'),
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
<style scoped lang="scss">
  .img {
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .title {
    font-size: 34px;
  }

  .des {
    line-height: 24px;
  }
</style>
